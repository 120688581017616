
@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

@layer components {
  .card {
    @apply border-solid rounded-sm p-2 
  }

  .active {
    @apply border border-black hover:cursor-pointer hover:bg-gray-100 active:bg-gray-100 active:text-slate-100 select-none
  }  

  .disabled {
    @apply border border-gray-200 bg-gray-200 rounded-sm text-slate-500	select-none
  }

  /* .cancel {
    @apply border border-orange-100 bg-orange-100 rounded-sm p-2 hover:bg-orange-300 hover:text-white
  } */
}
