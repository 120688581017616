:root {
  --yellow: #e6df4f;
  --blue: #233773;
  --red: #d23c28;
}

.GameArea {
  display: flex;
  flex-direction: column;
}

.Row {
  display: flex;
}

.Cell {
  width: 20px;
  height: 20px;
}

.Path {
  background-color: var(--yellow);
}

.Player {
  background-color: var(--blue);
  width: 100%;
  height: 100%;
}