.animate-in {
  animation-delay: calc(var(--index) * 130ms);
}

.listCompanies:hover a {
  opacity: 50%;
  transition: 500ms;
}

.listCompanies a:hover {
  opacity: 100%;
  transition: 500ms;
}