:root {
  --lightGrey: #909aa9;
  --mainColor: #e7ebe6;
  --textColor: #000000;
  --lightTextColor: #444444;
  --whiteColor: #FFFFFF;
}

.app {

}

@tailwind base;
@tailwind components;
@tailwind utilities;
